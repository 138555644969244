var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { staticClass: "pa-0 py-2", attrs: { cols: "12" } },
            [
              _c(
                "v-list-item-subtitle",
                {
                  staticClass:
                    "blue--text text--lighten-2 pa-1 white--text box-header",
                },
                [_vm._v(" Load dates ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-row",
        [
          _c(
            "v-container",
            {
              staticClass: "pa-0",
              class: _vm.$vuetify.breakpoint.mdAndUp ? "pt-0 hgt150" : "pt-0",
            },
            [
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    {
                      staticClass: "pt-0 pl-1",
                      attrs: { cols: "6", lg: "4", md: "6", sm: "6" },
                    },
                    [
                      _c(
                        "p",
                        { staticClass: "mb-1 grey--text text--darken-1" },
                        [_vm._v("Due Date Early")]
                      ),
                      _c(
                        "v-list-item-subtitle",
                        { staticClass: "grey--text text--darken-4" },
                        [
                          _c("tooltip", {
                            attrs: {
                              "tooltip-content": _vm.loadDates.dueDateEarly,
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    {
                      staticClass: "pt-0 pl-1",
                      attrs: { cols: "6", lg: "4", md: "6", sm: "6" },
                    },
                    [
                      _c(
                        "p",
                        { staticClass: "mb-1 grey--text text--darken-1" },
                        [_vm._v("Due Date Late")]
                      ),
                      _c(
                        "v-list-item-subtitle",
                        { staticClass: "grey--text text--darken-4" },
                        [
                          _c("tooltip", {
                            attrs: {
                              "tooltip-content": _vm.loadDates.dueDateLate,
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    {
                      staticClass: "pt-0 pl-1",
                      attrs: { cols: "6", lg: "4", md: "6", sm: "6" },
                    },
                    [
                      _c(
                        "p",
                        { staticClass: "mb-1 grey--text text--darken-1" },
                        [_vm._v("Created")]
                      ),
                      _c(
                        "v-list-item-subtitle",
                        { staticClass: "grey--text text--darken-4" },
                        [
                          _c("tooltip", {
                            attrs: {
                              "tooltip-content": _vm.loadDates.dateCreated,
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    {
                      staticClass: "pt-0 pl-1",
                      attrs: { cols: "6", lg: "4", md: "6", sm: "6" },
                    },
                    [
                      _c(
                        "p",
                        { staticClass: "mb-1 grey--text text--darken-1" },
                        [_vm._v("Booked")]
                      ),
                      _c(
                        "v-list-item-subtitle",
                        { staticClass: "grey--text text--darken-4" },
                        [
                          _c("tooltip", {
                            attrs: {
                              "tooltip-content": _vm.loadDates.bookedDate,
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    {
                      staticClass: "pt-0 pl-1",
                      attrs: { cols: "6", lg: "4", md: "6", sm: "6" },
                    },
                    [
                      _c(
                        "p",
                        { staticClass: "mb-1 grey--text text--darken-1" },
                        [_vm._v("Confirmed")]
                      ),
                      _c(
                        "v-list-item-subtitle",
                        { staticClass: "grey--text text--darken-4" },
                        [
                          _c("tooltip", {
                            attrs: {
                              "tooltip-content": _vm.loadDates.confirmedDate,
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    {
                      staticClass: "pt-0 pl-1",
                      attrs: { cols: "6", lg: "4", md: "6", sm: "6" },
                    },
                    [
                      _c(
                        "p",
                        { staticClass: "mb-1 grey--text text--darken-1" },
                        [_vm._v("Assigned")]
                      ),
                      _c(
                        "v-list-item-subtitle",
                        { staticClass: "grey--text text--darken-4" },
                        [
                          _c("tooltip", {
                            attrs: {
                              "tooltip-content": _vm.loadDates.assignedAt,
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    {
                      staticClass: "pt-0 pl-1",
                      attrs: { cols: "6", lg: "4", md: "6", sm: "6" },
                    },
                    [
                      _c(
                        "p",
                        { staticClass: "mb-1 grey--text text--darken-1" },
                        [_vm._v("Picked")]
                      ),
                      _c(
                        "v-list-item-subtitle",
                        { staticClass: "grey--text text--darken-4" },
                        [
                          _c("tooltip", {
                            attrs: {
                              "tooltip-content": _vm.loadDates.pickedDate,
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    {
                      staticClass: "pt-0 pl-1",
                      attrs: { cols: "6", lg: "4", md: "6", sm: "6" },
                    },
                    [
                      _c(
                        "p",
                        { staticClass: "mb-1 grey--text text--darken-1" },
                        [_vm._v("Delivered")]
                      ),
                      _c(
                        "v-list-item-subtitle",
                        { staticClass: "grey--text text--darken-4" },
                        [
                          _c("tooltip", {
                            attrs: {
                              "tooltip-content": _vm.loadDates.deliveredDate,
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    {
                      staticClass: "pt-0 pl-1",
                      attrs: { cols: "6", lg: "4", md: "6", sm: "6" },
                    },
                    [
                      _c(
                        "p",
                        { staticClass: "mb-1 grey--text text--darken-1" },
                        [_vm._v("Invoiced")]
                      ),
                      _c(
                        "v-list-item-subtitle",
                        { staticClass: "grey--text text--darken-4" },
                        [
                          _c("tooltip", {
                            attrs: {
                              "tooltip-content": _vm.loadDates.invoicedDate,
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    {
                      staticClass: "pt-0 pl-1",
                      attrs: { cols: "6", lg: "4", md: "6", sm: "6" },
                    },
                    [
                      _c(
                        "p",
                        { staticClass: "mb-1 grey--text text--darken-1" },
                        [_vm._v("Paid")]
                      ),
                      _c(
                        "v-list-item-subtitle",
                        { staticClass: "grey--text text--darken-4" },
                        [
                          _c("tooltip", {
                            attrs: {
                              "tooltip-content": _vm.loadDates.paidDate,
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    {
                      staticClass: "pt-0 pl-1",
                      attrs: { cols: "6", lg: "4", md: "6", sm: "6" },
                    },
                    [
                      _c(
                        "p",
                        { staticClass: "mb-1 grey--text text--darken-1" },
                        [_vm._v("Updated")]
                      ),
                      _c(
                        "v-list-item-subtitle",
                        { staticClass: "grey--text text--darken-4" },
                        [
                          _c("tooltip", {
                            attrs: {
                              "tooltip-content": _vm.loadDates.updatedAt,
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    {
                      staticClass: "pt-0 pl-1",
                      attrs: { cols: "6", lg: "4", md: "6", sm: "6" },
                    },
                    [
                      _c(
                        "p",
                        { staticClass: "mb-1 grey--text text--darken-1" },
                        [_vm._v("Archived")]
                      ),
                      _c(
                        "v-list-item-subtitle",
                        { staticClass: "grey--text text--darken-4" },
                        [
                          _c("tooltip", {
                            attrs: {
                              "tooltip-content": _vm.loadDates.archivedAt,
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }