var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("v-autocomplete", {
        staticClass: "mt-n2",
        attrs: {
          items: _vm.orderTypes,
          loading: _vm.loading,
          "search-input": _vm.search,
          color: "info",
          clearable: "",
          "item-text": "name",
          "item-value": "id",
          label: "Order Type",
          "return-object": "",
          required: "",
          "close-on-content-click": "",
          rules: [(v) => !!v || `Order type is required`],
        },
        on: {
          "update:searchInput": function ($event) {
            _vm.search = $event
          },
          "update:search-input": function ($event) {
            _vm.search = $event
          },
          keydown: function ($event) {
            if (
              !$event.type.indexOf("key") &&
              _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
            )
              return null
            return $event.stopPropagation()
          },
          blur: function ($event) {
            return _vm.pushOrderType()
          },
          "click:clear": function ($event) {
            return _vm.removeOrderType()
          },
        },
        model: {
          value: _vm.orderType,
          callback: function ($$v) {
            _vm.orderType = $$v
          },
          expression: "orderType",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }