var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    {
      staticClass: "mt-0",
      attrs: { height: _vm.$vuetify.breakpoint.mdAndUp ? "350" : "auto" },
    },
    [
      _vm.uploadProgress > 0
        ? _c(
            "v-progress-linear",
            {
              attrs: { height: "25" },
              model: {
                value: _vm.uploadProgress,
                callback: function ($$v) {
                  _vm.uploadProgress = $$v
                },
                expression: "uploadProgress",
              },
            },
            [
              _c("strong", [
                _vm._v(_vm._s(Math.ceil(_vm.uploadProgress)) + "%"),
              ]),
            ]
          )
        : _vm._e(),
      _c(
        "v-tabs",
        {
          attrs: { "fixed-tab": "", grow: "" },
          on: { change: _vm.changeTab },
          model: {
            value: _vm.currentTab,
            callback: function ($$v) {
              _vm.currentTab = $$v
            },
            expression: "currentTab",
          },
        },
        _vm._l(_vm.tabs, function (tab) {
          return _c(
            "v-tab",
            { key: tab.href, attrs: { href: `#tab-${tab.href}` } },
            [_vm._v(" " + _vm._s(tab.title) + " ")]
          )
        }),
        1
      ),
      _c(
        "v-tabs-items",
        {
          model: {
            value: _vm.currentTab,
            callback: function ($$v) {
              _vm.currentTab = $$v
            },
            expression: "currentTab",
          },
        },
        [
          _vm._l(_vm.tabs, function (tab) {
            return _c(
              "v-tab-item",
              {
                key: tab.href,
                staticClass: "pt-2",
                staticStyle: { "overflow-y": "auto" },
                style: _vm.$vuetify.breakpoint.mdAndUp
                  ? "height:218px"
                  : "height:285px",
                attrs: { value: `tab-${tab.href}` },
                on: { scroll: _vm.onScroll },
              },
              [
                _c(
                  "v-card",
                  { staticClass: "mt-1", attrs: { flat: "" } },
                  [
                    !_vm.isAuditLogTabActive
                      ? _c(
                          "v-card-text",
                          _vm._l(tab.content, function (content, index) {
                            return _c(
                              "div",
                              { key: index },
                              [
                                _c(
                                  "p",
                                  {
                                    staticClass: "grey--text text-caption mb-2",
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.formatDate(content.date)) +
                                        " | " +
                                        _vm._s(content.userFullName) +
                                        " "
                                    ),
                                  ]
                                ),
                                _vm.isFileUpload
                                  ? _c(
                                      "p",
                                      {
                                        staticClass:
                                          "grey--text text-caption mb-2",
                                      },
                                      [
                                        _c("b", [_vm._v("Type:")]),
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              content.documentTypeDisplay
                                            ) +
                                            " "
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                                _c("p", {
                                  staticClass: "text-body-1 mb-2",
                                  domProps: {
                                    innerHTML: _vm._s(
                                      content.text.replace(
                                        /(?:\r\n|\r|\n)/g,
                                        "<br>"
                                      )
                                    ),
                                  },
                                }),
                                _c(
                                  "v-card-actions",
                                  { staticClass: "pl-0 pt-0" },
                                  [
                                    _vm.footerBtn1.length > 0
                                      ? _c(
                                          "v-btn",
                                          {
                                            attrs: {
                                              color:
                                                _vm.footerBtn1 === "Send"
                                                  ? "success"
                                                  : "normal",
                                              small: "",
                                              text: "",
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.editAndSendEvent(
                                                  tab.href,
                                                  content
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _vm._v(
                                              " " + _vm._s(_vm.footerBtn1) + " "
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                    _vm.footerBtn2.length > 0
                                      ? _c(
                                          "v-btn",
                                          {
                                            attrs: {
                                              color: "error",
                                              small: "",
                                              text: "",
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.deleteItemEvent(
                                                  tab.href,
                                                  content
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _vm._v(
                                              " " + _vm._s(_vm.footerBtn2) + " "
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                                _c("v-divider", { staticClass: "pb-2" }),
                              ],
                              1
                            )
                          }),
                          0
                        )
                      : _vm._e(),
                    _vm.isAuditLogTabActive
                      ? _c(
                          "div",
                          { staticClass: "px-4" },
                          [
                            !_vm.loadingIcon && !_vm.auditLog.results.length
                              ? _c("div", { staticClass: "text-center" }, [
                                  _vm._v(" There are no changes yet. "),
                                ])
                              : _vm._e(),
                            _vm._l(_vm.auditLog.results, function (item) {
                              return _c(
                                "div",
                                { key: item.timeStamp },
                                [
                                  _c("p", { staticClass: "grey--text mb-2" }, [
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.formatDate(item.timeStamp)) +
                                        " | " +
                                        _vm._s(item.username) +
                                        " " +
                                        _vm._s(item.changeType.toLowerCase()) +
                                        " "
                                    ),
                                  ]),
                                  _vm._l(item.changes, function (change) {
                                    return _c("div", { key: change.field }, [
                                      _c(
                                        "p",
                                        { staticClass: "text-body-1 mb-2" },
                                        [
                                          _c(
                                            "span",
                                            { staticClass: "font-weight-bold" },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  change.field.replaceAll(
                                                    "_",
                                                    " "
                                                  )
                                                )
                                              ),
                                            ]
                                          ),
                                          _vm._v(" from "),
                                          _c(
                                            "span",
                                            { staticClass: "font-weight-bold" },
                                            [_vm._v(_vm._s(change.old))]
                                          ),
                                          _vm._v(" to "),
                                          _c(
                                            "span",
                                            { staticClass: "font-weight-bold" },
                                            [_vm._v(_vm._s(change.new))]
                                          ),
                                        ]
                                      ),
                                    ])
                                  }),
                                  _c("v-divider", { staticClass: "pb-2" }),
                                ],
                                2
                              )
                            }),
                          ],
                          2
                        )
                      : _vm._e(),
                  ],
                  1
                ),
              ],
              1
            )
          }),
          _c(
            "div",
            {
              staticClass: "rounded grey lighten-4",
              staticStyle: {
                "min-height": "82px",
                "border-radius": "0 0 4px 4px",
                "margin-top": "2px",
              },
            },
            [
              _c(
                "v-row",
                { staticClass: "ma-0" },
                [
                  _c(
                    "v-col",
                    {
                      staticClass: "mt-3 pa-0",
                      attrs: {
                        cols: _vm.$vuetify.breakpoint.lgAndUp ? "9" : "8",
                      },
                    },
                    [
                      _c(
                        "v-row",
                        [
                          _vm.isNotes
                            ? _c(
                                "v-form",
                                {
                                  ref: "note-form",
                                  staticClass: "col-md-12",
                                  attrs: { "lazy-validation": "" },
                                  model: {
                                    value: _vm.noteFormValid,
                                    callback: function ($$v) {
                                      _vm.noteFormValid = $$v
                                    },
                                    expression: "noteFormValid",
                                  },
                                },
                                [
                                  _c("v-textarea", {
                                    staticClass: "pl-5 pt-0",
                                    attrs: {
                                      rules: _vm.noteValidations,
                                      clearable: "",
                                      "no-resize": "",
                                      required: "",
                                      rows: "2",
                                    },
                                    model: {
                                      value: _vm.txtnote,
                                      callback: function ($$v) {
                                        _vm.txtnote = $$v
                                      },
                                      expression: "txtnote",
                                    },
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.isFileUpload
                            ? _c(
                                "v-form",
                                {
                                  ref: "upload-form",
                                  staticClass: "col-md-12",
                                  attrs: { "lazy-validation": "" },
                                  model: {
                                    value: _vm.uploadFormValid,
                                    callback: function ($$v) {
                                      _vm.uploadFormValid = $$v
                                    },
                                    expression: "uploadFormValid",
                                  },
                                },
                                [
                                  _c(
                                    "v-row",
                                    [
                                      _c(
                                        "v-col",
                                        {
                                          staticClass: "pa-0",
                                          attrs: { cols: "6" },
                                        },
                                        [
                                          _vm.isFileUpload
                                            ? _c("v-file-input", {
                                                staticClass: "pl-4 pt-0",
                                                attrs: {
                                                  rules: [_vm.rules.required],
                                                  "show-size":
                                                    _vm.$vuetify.breakpoint
                                                      .lgAndUp,
                                                  counter: "",
                                                  label: "File input",
                                                  required: "",
                                                },
                                                on: { change: _vm.selectFiles },
                                                model: {
                                                  value: _vm.fileInput,
                                                  callback: function ($$v) {
                                                    _vm.fileInput = $$v
                                                  },
                                                  expression: "fileInput",
                                                },
                                              })
                                            : _vm._e(),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-col",
                                        {
                                          staticClass: "pa-0",
                                          attrs: { cols: "6" },
                                        },
                                        [
                                          _vm.isFileUpload
                                            ? _c("v-select", {
                                                staticClass: "pt-0",
                                                class: _vm.$vuetify.breakpoint
                                                  .lgAndUp
                                                  ? "pl-2"
                                                  : "pl-6",
                                                attrs: {
                                                  items: _vm.documentType,
                                                  rules: [_vm.rules.required],
                                                  color: "info",
                                                  "item-text": "name",
                                                  "item-value": "id",
                                                  label: "Document Type",
                                                },
                                                model: {
                                                  value: _vm.document,
                                                  callback: function ($$v) {
                                                    _vm.document = $$v
                                                  },
                                                  expression: "document",
                                                },
                                              })
                                            : _vm._e(),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.isAuditLogTabActive
                            ? _c("v-col", { attrs: { cols: "12" } }, [
                                _c(
                                  "div",
                                  { staticClass: "px-4" },
                                  [
                                    _c("v-autocomplete", {
                                      attrs: {
                                        items: _vm.fieldNameOptions,
                                        placeholder: "Filter the audit log",
                                        clearable: "",
                                      },
                                      on: { change: _vm.getAuditLog },
                                      model: {
                                        value: _vm.fieldName,
                                        callback: function ($$v) {
                                          _vm.fieldName = $$v
                                        },
                                        expression: "fieldName",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ])
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  !_vm.isAuditLogTabActive
                    ? _c(
                        "v-col",
                        { staticClass: "mt-0 pt-6", attrs: { cols: "3" } },
                        [
                          _c(
                            "v-btn",
                            {
                              staticClass: "mt-2",
                              class: _vm.sendBtn.class,
                              attrs: { absolute: "", right: "", small: "" },
                              on: { click: _vm.submitAction },
                            },
                            [
                              _c(
                                "v-icon",
                                { staticClass: "mr-1", attrs: { small: "" } },
                                [_vm._v(" " + _vm._s(_vm.sendBtn.img) + " ")]
                              ),
                              _vm._v(" " + _vm._s(_vm.sendBtn.text) + " "),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }