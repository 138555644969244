var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { attrs: { cols: "6" } },
            [
              _c(
                "v-card-title",
                {
                  staticClass:
                    "blue-grey--text lighten-3 text-subtitle-1 float-left",
                },
                [_vm._v(" #" + _vm._s(_vm.shipmentDetail.id) + " ")]
              ),
            ],
            1
          ),
          _c(
            "v-col",
            { staticClass: "text-right", attrs: { cols: "6" } },
            [
              _c(
                "v-btn",
                {
                  attrs: {
                    disabled: _vm.disableStatusButton,
                    color: "grey",
                    small: "",
                    text: "",
                  },
                  on: {
                    click: function ($event) {
                      return _vm.saveLoadUpdate()
                    },
                  },
                },
                [_vm._v(" Save ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-form",
        {
          ref: "shipment-form",
          attrs: { "lazy-validation": "" },
          model: {
            value: _vm.shipmentDetailFormValid,
            callback: function ($$v) {
              _vm.shipmentDetailFormValid = $$v
            },
            expression: "shipmentDetailFormValid",
          },
        },
        [
          _c(
            "v-row",
            { staticClass: "pl-3" },
            [
              _c(
                "v-col",
                {
                  staticClass: "pt-0 pb-0",
                  attrs: { cols: _vm.$vuetify.breakpoint.lgAndUp ? "6" : "12" },
                },
                [
                  _c("customer-auto-complete", {
                    attrs: { "default-customer": _vm.shipmentDetail.customer },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-row",
            { staticClass: "pl-3" },
            [
              _c(
                "v-col",
                { staticClass: "pt-0 pb-0", attrs: { cols: "6" } },
                [
                  _c(
                    "v-menu",
                    {
                      ref: "pickupDate",
                      attrs: {
                        "close-on-content-click": false,
                        "min-width": "290px",
                        "offset-x": "",
                        transition: "scale-transition",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "activator",
                          fn: function ({ on, attrs }) {
                            return [
                              _c(
                                "v-text-field",
                                _vm._g(
                                  _vm._b(
                                    {
                                      staticClass: "pt-0 pb-0",
                                      attrs: {
                                        label: "Pickup Date",
                                        readonly: "",
                                      },
                                      model: {
                                        value: _vm.shipmentDetail.pickupDate,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.shipmentDetail,
                                            "pickupDate",
                                            $$v
                                          )
                                        },
                                        expression: "shipmentDetail.pickupDate",
                                      },
                                    },
                                    "v-text-field",
                                    attrs,
                                    false
                                  ),
                                  on
                                )
                              ),
                            ]
                          },
                        },
                      ]),
                      model: {
                        value: _vm.pickupDateMenu,
                        callback: function ($$v) {
                          _vm.pickupDateMenu = $$v
                        },
                        expression: "pickupDateMenu",
                      },
                    },
                    [
                      _c("v-date-picker", {
                        attrs: { "no-title": "", scrollable: "" },
                        on: {
                          input: function ($event) {
                            _vm.pickupDateMenu = false
                          },
                        },
                        model: {
                          value: _vm.shipmentDetail.pickupDate,
                          callback: function ($$v) {
                            _vm.$set(_vm.shipmentDetail, "pickupDate", $$v)
                          },
                          expression: "shipmentDetail.pickupDate",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-col",
                { staticClass: "pt-0 pb-0", attrs: { cols: "6" } },
                [
                  _c(
                    "v-menu",
                    {
                      ref: "deliveryDate",
                      attrs: {
                        "close-on-content-click": false,
                        "min-width": "290px",
                        "offset-x": "",
                        transition: "scale-transition",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "activator",
                          fn: function ({ on, attrs }) {
                            return [
                              _c(
                                "v-text-field",
                                _vm._g(
                                  _vm._b(
                                    {
                                      staticClass: "pt-0 pb-0",
                                      attrs: {
                                        label: "Delivery Date",
                                        readonly: "",
                                      },
                                      model: {
                                        value: _vm.shipmentDetail.deliveryDate,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.shipmentDetail,
                                            "deliveryDate",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "shipmentDetail.deliveryDate",
                                      },
                                    },
                                    "v-text-field",
                                    attrs,
                                    false
                                  ),
                                  on
                                )
                              ),
                            ]
                          },
                        },
                      ]),
                      model: {
                        value: _vm.deliveryDateMenu,
                        callback: function ($$v) {
                          _vm.deliveryDateMenu = $$v
                        },
                        expression: "deliveryDateMenu",
                      },
                    },
                    [
                      _c("v-date-picker", {
                        attrs: { "no-title": "", scrollable: "" },
                        on: {
                          input: function ($event) {
                            _vm.deliveryDateMenu = false
                          },
                        },
                        model: {
                          value: _vm.shipmentDetail.deliveryDate,
                          callback: function ($$v) {
                            _vm.$set(_vm.shipmentDetail, "deliveryDate", $$v)
                          },
                          expression: "shipmentDetail.deliveryDate",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-row",
            { staticClass: "pl-3" },
            [
              _c(
                "v-col",
                {
                  staticClass: "pt-0 pb-0",
                  attrs: { cols: _vm.$vuetify.breakpoint.lgAndUp ? "4" : "6" },
                },
                [
                  _c("equipment-type-auto-complete", {
                    attrs: { equipment: _vm.equipmentType },
                  }),
                ],
                1
              ),
              _c(
                "v-col",
                {
                  staticClass: "pt-0 pb-0",
                  attrs: { cols: _vm.$vuetify.breakpoint.lgAndUp ? "4" : "6" },
                },
                [
                  _c("order-type-auto-complete", {
                    attrs: { model: _vm.shipmentOrderType },
                  }),
                ],
                1
              ),
              _c(
                "v-col",
                {
                  staticClass: "pt-0 pb-0 mt-n3",
                  attrs: { cols: _vm.$vuetify.breakpoint.lgAndUp ? "4" : "6" },
                },
                [
                  _c("agent-auto-complete", {
                    attrs: { "default-agent": _vm.shipmentDetail.agent },
                  }),
                ],
                1
              ),
              _c(
                "v-col",
                {
                  staticClass: "pt-0 pb-0",
                  attrs: { cols: _vm.$vuetify.breakpoint.lgAndUp ? "4" : "6" },
                },
                [_c("csr-auto-complete")],
                1
              ),
              _c(
                "v-col",
                {
                  staticClass: "pt-0 pb-0",
                  attrs: { cols: _vm.$vuetify.breakpoint.lgAndUp ? "4" : "6" },
                },
                [
                  _c("v-text-field", {
                    staticClass: "pt-0",
                    attrs: { label: "Customer Ref. ID" },
                    nativeOn: {
                      keydown: function ($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        )
                          return null
                        return _vm.saveLoadUpdate()
                      },
                    },
                    model: {
                      value: _vm.shipmentDetail.customerReferenceId,
                      callback: function ($$v) {
                        _vm.$set(_vm.shipmentDetail, "customerReferenceId", $$v)
                      },
                      expression: "shipmentDetail.customerReferenceId",
                    },
                  }),
                ],
                1
              ),
              _c(
                "v-col",
                {
                  staticClass: "pt-0 pb-0",
                  attrs: { cols: _vm.$vuetify.breakpoint.lgAndUp ? "4" : "6" },
                },
                [
                  _c("v-text-field", {
                    staticClass: "pt-0",
                    attrs: { label: "PO Number" },
                    nativeOn: {
                      keydown: function ($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        )
                          return null
                        return _vm.saveLoadUpdate()
                      },
                    },
                    model: {
                      value: _vm.shipmentDetail.poNumber,
                      callback: function ($$v) {
                        _vm.$set(_vm.shipmentDetail, "poNumber", $$v)
                      },
                      expression: "shipmentDetail.poNumber",
                    },
                  }),
                ],
                1
              ),
              _c(
                "v-col",
                {
                  staticClass: "pt-0 pb-0",
                  attrs: { cols: _vm.$vuetify.breakpoint.lgAndUp ? "4" : "6" },
                },
                [
                  _c("v-select", {
                    staticClass: "pt-0",
                    attrs: {
                      items: _vm.modes,
                      clearable: "",
                      "item-text": "name",
                      "item-value": "id",
                      label: "Mode Type",
                    },
                    model: {
                      value: _vm.shipmentDetail.mode,
                      callback: function ($$v) {
                        _vm.$set(_vm.shipmentDetail, "mode", $$v)
                      },
                      expression: "shipmentDetail.mode",
                    },
                  }),
                ],
                1
              ),
              _c(
                "v-col",
                {
                  staticClass: "pt-0 pb-0",
                  attrs: { cols: _vm.$vuetify.breakpoint.lgAndUp ? "4" : "6" },
                },
                [
                  _c("v-text-field", {
                    staticClass: "pt-0",
                    attrs: { label: "Carrier Invoice Number" },
                    nativeOn: {
                      keydown: function ($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        )
                          return null
                        return _vm.saveLoadUpdate()
                      },
                    },
                    model: {
                      value: _vm.shipmentDetail.carrierInvoiceNumber,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.shipmentDetail,
                          "carrierInvoiceNumber",
                          $$v
                        )
                      },
                      expression: "shipmentDetail.carrierInvoiceNumber",
                    },
                  }),
                ],
                1
              ),
              _c(
                "v-col",
                {
                  staticClass: "pt-0 pb-0",
                  attrs: { cols: _vm.$vuetify.breakpoint.lgAndUp ? "4" : "6" },
                },
                [
                  _c("v-text-field", {
                    staticClass: "pt-0",
                    attrs: { label: "Billing Reference Number" },
                    nativeOn: {
                      keydown: function ($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        )
                          return null
                        return _vm.saveLoadUpdate()
                      },
                    },
                    model: {
                      value: _vm.shipmentDetail.billingReferenceNumber,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.shipmentDetail,
                          "billingReferenceNumber",
                          $$v
                        )
                      },
                      expression: "shipmentDetail.billingReferenceNumber",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }