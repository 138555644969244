var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-stepper",
        {
          staticClass: "elevation-0 pl-0 pr-0 pb-0",
          attrs: { "alt-labels": "" },
          model: {
            value: _vm.currentStep,
            callback: function ($$v) {
              _vm.currentStep = $$v
            },
            expression: "currentStep",
          },
        },
        [
          _c(
            "v-stepper-header",
            [
              _c(
                "v-stepper-step",
                {
                  staticClass: "ml-n15 pb-0 pt-1",
                  attrs: { complete: _vm.currentStep > 0, step: "1" },
                },
                [
                  _c(
                    "p",
                    {
                      staticClass: "grey--text text--darken-1 mb-1 text-center",
                    },
                    [_vm._v("Plan")]
                  ),
                ]
              ),
              _c("v-divider"),
              _c(
                "v-stepper-step",
                {
                  staticClass: "pb-0 pt-1",
                  attrs: { complete: _vm.currentStep > 1, step: "2" },
                },
                [
                  _c(
                    "p",
                    {
                      staticClass: "grey--text text--darken-1 mb-1 text-center",
                    },
                    [_vm._v("Assigned")]
                  ),
                ]
              ),
              _c("v-divider"),
              _c(
                "v-stepper-step",
                {
                  staticClass: "pb-0 pt-1",
                  attrs: { complete: _vm.currentStep > 2, step: "3" },
                },
                [
                  _c(
                    "p",
                    {
                      staticClass: "grey--text text--darken-1 mb-1 text-center",
                    },
                    [
                      _vm._v(" Pickup "),
                      _c("br"),
                      _c(
                        "span",
                        {
                          staticClass:
                            "font-weight-bold grey--text text--darken-4",
                        },
                        [
                          _c("render-date", {
                            attrs: { date: _vm.shipmentDetail.pickupDate },
                          }),
                        ],
                        1
                      ),
                    ]
                  ),
                ]
              ),
              _c("v-divider"),
              _c(
                "v-stepper-step",
                {
                  staticClass: "pb-0 pt-1",
                  attrs: { complete: _vm.currentStep > 3, step: "4" },
                },
                [
                  _c(
                    "p",
                    {
                      staticClass: "grey--text text--darken-1 mb-1 text-center",
                    },
                    [
                      _vm._v(" Delivery"),
                      _c("br"),
                      _c(
                        "span",
                        {
                          staticClass:
                            "font-weight-bold grey--text text--darken-4",
                        },
                        [
                          _c("render-date", {
                            attrs: { date: _vm.shipmentDetail.deliveryDate },
                          }),
                        ],
                        1
                      ),
                    ]
                  ),
                ]
              ),
              _c("v-divider"),
              _c(
                "v-stepper-step",
                {
                  class:
                    _vm.currentStep === 6 ? "pb-0 pt-1" : "mr-n15 pb-0 pt-1",
                  attrs: {
                    color: "green",
                    complete: _vm.currentStep > 4,
                    step: "5",
                  },
                },
                [
                  _c(
                    "p",
                    {
                      staticClass: "grey--text text--darken-1 mb-1 text-center",
                    },
                    [_vm._v("Invoiced")]
                  ),
                ]
              ),
              _vm.currentStep === 6 ? _c("v-divider") : _vm._e(),
              _vm.currentStep === 6
                ? _c(
                    "v-stepper-step",
                    {
                      staticClass: "mr-n15 pb-0 pt-1",
                      attrs: {
                        color: "red",
                        complete: _vm.currentStep > 5,
                        step: "6",
                      },
                    },
                    [
                      _c(
                        "p",
                        {
                          staticClass:
                            "grey--text text--darken-1 mb-1 text-center",
                        },
                        [_vm._v("Claim")]
                      ),
                    ]
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }