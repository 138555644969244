var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("v-data-table", {
    attrs: {
      headers: _vm.headers,
      items: _vm.carrierMatchList,
      "items-per-page": _vm.pageSize,
      "hide-default-footer": "",
      "calculate-widths": "",
      "fixed-header": "",
    },
    scopedSlots: _vm._u([
      {
        key: "item.matchType",
        fn: function ({ item }) {
          return [
            _c("v-icon", { attrs: { title: "Historical load" } }, [
              _vm._v(" " + _vm._s(_vm.matchTypeIcon(item.matchType)) + " "),
            ]),
          ]
        },
      },
      {
        key: "item.dotNumber",
        fn: function ({ item }) {
          return [
            _c("span", { staticClass: "grey--text text--darken-2" }, [
              _vm._v("MC:"),
            ]),
            _vm._v(" " + _vm._s(item.mcNumber)),
            _c("br"),
            _c("span", { staticClass: "grey--text text--darken-2" }, [
              _vm._v("DOT:"),
            ]),
            _vm._v(" " + _vm._s(item.dotNumber) + " "),
          ]
        },
      },
      {
        key: "item.phone",
        fn: function ({ item }) {
          return [
            _c("span", { staticClass: "grey--text text--darken-2" }, [
              _vm._v("Phone:"),
            ]),
            _c("a", { attrs: { href: `tel:${item.phone}` } }, [
              _vm._v(_vm._s(item.phone)),
            ]),
            _vm._v(" "),
            _c("br"),
            _c("span", { staticClass: "grey--text text--darken-2" }, [
              _vm._v("Email:"),
            ]),
            _c(
              "a",
              {
                attrs: {
                  target: "_blank",
                  href: `https://mail.google.com/mail/?view=cm&fs=1&to=${item.email}`,
                },
              },
              [_vm._v(_vm._s(item.email))]
            ),
          ]
        },
      },
      {
        key: "item.name",
        fn: function ({ item }) {
          return [
            _c(
              "router-link",
              {
                staticStyle: { color: "#212121", "text-decoration": "none" },
                attrs: {
                  target: "_blank",
                  to: { name: "Carrier Detail", params: { id: item.id } },
                },
              },
              [_vm._v(" " + _vm._s(item.name) + " ")]
            ),
          ]
        },
      },
      {
        key: "footer",
        fn: function () {
          return [
            _c("v-page", {
              staticClass: "pt-3 pr-3 pb-3",
              attrs: {
                "total-row": _vm.totalRecord,
                "page-size-menu": [10, 20, 50, 100],
              },
              on: { "page-change": _vm.readDataFromAPI },
            }),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }