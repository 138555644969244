var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "matches-detail" },
    [
      _c(
        "v-dialog",
        {
          attrs: {
            fullscreen: _vm.$vuetify.breakpoint.smAndDown ? false : true,
            "content-class": "match-detail-dialog",
            persistent: "",
            transition: "slide-x-reverse-transition",
          },
          model: {
            value: _vm.dialog,
            callback: function ($$v) {
              _vm.dialog = $$v
            },
            expression: "dialog",
          },
        },
        [
          _c(
            "v-toolbar",
            {
              staticStyle: { "background-color": "#1e88e5" },
              attrs: { dark: "" },
            },
            [
              _c(
                "v-btn",
                {
                  attrs: { dark: "", icon: "" },
                  on: {
                    click: function ($event) {
                      return _vm.closeDialog()
                    },
                  },
                },
                [_c("v-icon", [_vm._v("mdi-close")]), _c("p")],
                1
              ),
              _vm.dialogLoad
                ? _c("v-toolbar-title", [
                    _c("b", [
                      _vm._v(
                        " " +
                          _vm._s(_vm.dialogLoad.customer.name) +
                          " | " +
                          _vm._s(_vm.dialogLoad.id)
                      ),
                    ]),
                    _vm._v(" - " + _vm._s(_vm.dialogLoad.equipmentType) + " "),
                  ])
                : _vm._e(),
            ],
            1
          ),
          _vm.dialogLoad
            ? _c(
                "v-container",
                { staticClass: "pb-0", attrs: { fluid: "" } },
                [
                  _c(
                    "v-row",
                    { staticStyle: { "background-color": "#ffffff" } },
                    [
                      _c(
                        "v-col",
                        {
                          staticClass: "pb-0 pt-0",
                          attrs: { cols: "12", md: "6" },
                        },
                        [
                          _c(
                            "v-list-item-title",
                            { staticClass: "text-h5 mb-1" },
                            [
                              _c(
                                "v-icon",
                                { attrs: { color: "green", "x-large": "" } },
                                [_vm._v(" mdi-map-marker")]
                              ),
                              _vm._v(" Origin "),
                            ],
                            1
                          ),
                          _c("p", { staticClass: "mb-0" }, [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.formatLocation(
                                    _vm.origin.city,
                                    _vm.origin.stateProvinceRegion
                                  )
                                ) +
                                " | " +
                                _vm._s(
                                  _vm.formatDate(_vm.dialogLoad.pickupDate)
                                ) +
                                " "
                            ),
                          ]),
                          _c("p", { staticClass: "mb-0" }, [
                            _vm._v(
                              " " +
                                _vm._s(_vm.loadTimeInfo.shipperEarlyTime) +
                                " - " +
                                _vm._s(_vm.loadTimeInfo.shipperLateTime) +
                                " "
                            ),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        {
                          staticClass: "pb-0 pt-0",
                          attrs: { cols: "12", md: "6" },
                        },
                        [
                          _c(
                            "v-list-item-title",
                            { staticClass: "text-h5 mb-1" },
                            [
                              _c(
                                "v-icon",
                                { attrs: { color: "red", "x-large": "" } },
                                [_vm._v(" mdi-map-marker")]
                              ),
                              _vm._v(" Destination "),
                            ],
                            1
                          ),
                          _c("p", { staticClass: "mb-0" }, [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.formatLocation(
                                    _vm.destination.city,
                                    _vm.destination.stateProvinceRegion
                                  )
                                ) +
                                " | " +
                                _vm._s(
                                  _vm.formatDate(_vm.dialogLoad.deliveryDate)
                                ) +
                                " "
                            ),
                          ]),
                          _c("p", { staticClass: "mb-0" }, [
                            _vm._v(
                              " " +
                                _vm._s(_vm.loadTimeInfo.consigneeEarlyTime) +
                                " - " +
                                _vm._s(_vm.loadTimeInfo.consigneeLateTime) +
                                " "
                            ),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    { staticStyle: { "background-color": "#ffffff" } },
                    [
                      _c(
                        "v-col",
                        {
                          staticClass: "pb-0 pt-2",
                          attrs: { cols: "4", md: "2" },
                        },
                        [
                          _c(
                            "v-list-item-title",
                            { staticClass: "text-h4 mb-1" },
                            [_c("b", [_vm._v("Miles")])]
                          ),
                          _c("p", { staticClass: "mb-1" }, [
                            _vm._v(" " + _vm._s(_vm.commodityInfo.miles) + " "),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        {
                          staticClass: "pt-2 pb-0",
                          attrs: { cols: "4", md: "2" },
                        },
                        [
                          _c(
                            "v-list-item-title",
                            { staticClass: "text-h4 mb-1" },
                            [_c("b", [_vm._v("Height")])]
                          ),
                          _c("p", { staticClass: "mb-1" }, [
                            _vm._v(
                              " " + _vm._s(_vm.commodityInfo.loadHeight) + " "
                            ),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        {
                          staticClass: "pt-2 pb-0",
                          attrs: { cols: "4", md: "2" },
                        },
                        [
                          _c(
                            "v-list-item-title",
                            { staticClass: "text-h4 mb-1" },
                            [_c("b", [_vm._v("Weight")])]
                          ),
                          _c("p", { staticClass: "mb-1" }, [
                            _vm._v(
                              " " + _vm._s(_vm.commodityInfo.loadWeight) + " "
                            ),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        {
                          staticClass: "pt-2 pb-0",
                          attrs: { cols: "4", md: "2" },
                        },
                        [
                          _c(
                            "v-list-item-title",
                            { staticClass: "text-h4 mb-1" },
                            [_c("b", [_vm._v("Length")])]
                          ),
                          _c("p", { staticClass: "mb-1" }, [
                            _vm._v(
                              " " + _vm._s(_vm.commodityInfo.loadLength) + " "
                            ),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        {
                          staticClass: "pt-2 pb-0",
                          attrs: { cols: "4", md: "2" },
                        },
                        [
                          _c(
                            "v-list-item-title",
                            { staticClass: "text-h4 mb-1" },
                            [_c("b", [_vm._v("Width")])]
                          ),
                          _c("p", { staticClass: "mb-1" }, [
                            _vm._v(
                              " " + _vm._s(_vm.commodityInfo.loadWidth) + " "
                            ),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        {
                          staticClass: "pt-2 pb-0",
                          attrs: { cols: "4", md: "2" },
                        },
                        [
                          _c(
                            "v-list-item-title",
                            { staticClass: "text-h4 mb-1" },
                            [_c("b", [_vm._v("Commodity")])]
                          ),
                          _c("p", { staticClass: "mb-1" }, [
                            _vm._v(
                              " " + _vm._s(_vm.commodityInfo.commodity) + " "
                            ),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    {
                      staticStyle: {
                        "border-top": "solid 1px #ddd",
                        height: "100%",
                      },
                    },
                    [
                      _c(
                        "v-container",
                        [
                          _c(
                            "v-expansion-panels",
                            {
                              attrs: { multiple: "" },
                              model: {
                                value: _vm.panel,
                                callback: function ($$v) {
                                  _vm.panel = $$v
                                },
                                expression: "panel",
                              },
                            },
                            [
                              _c(
                                "v-expansion-panel",
                                [
                                  _c("v-expansion-panel-header", [
                                    _vm._v(" Quotes"),
                                  ]),
                                  _c(
                                    "v-expansion-panel-content",
                                    {
                                      staticStyle: {
                                        "max-height": "300px",
                                        "overflow-y": "auto",
                                      },
                                    },
                                    [
                                      _c("v-data-table", {
                                        attrs: {
                                          headers: _vm.headers,
                                          items: _vm.quoteList,
                                          "items-per-page": _vm.pageSize,
                                          "calculate-widths": "",
                                          "fixed-header": "",
                                          "hide-default-footer": "",
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "item.name",
                                              fn: function ({ item }) {
                                                return [
                                                  item.carrierCompany
                                                    ? _c(
                                                        "router-link",
                                                        {
                                                          staticClass:
                                                            "name-link",
                                                          attrs: {
                                                            to: {
                                                              name: "Carrier Detail",
                                                              params: {
                                                                id: item.carrierCompany,
                                                              },
                                                            },
                                                            target: "_blank",
                                                          },
                                                        },
                                                        [
                                                          _c("span", [
                                                            _vm._v(
                                                              _vm._s(item.name)
                                                            ),
                                                          ]),
                                                        ]
                                                      )
                                                    : _c(
                                                        "span",
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                item.name
                                                              ) +
                                                              " "
                                                          ),
                                                          _c(
                                                            "v-icon",
                                                            {
                                                              attrs: {
                                                                color: "red",
                                                                "x-small": "",
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                "mdi-bus-alert"
                                                              ),
                                                            ]
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                ]
                                              },
                                            },
                                            {
                                              key: "footer",
                                              fn: function () {
                                                return [
                                                  _c("v-page", {
                                                    staticClass:
                                                      "pt-3 pr-3 pb-3",
                                                    attrs: {
                                                      "page-size-menu": [
                                                        10, 20, 50, 100,
                                                      ],
                                                      "total-row":
                                                        _vm.totalRecord,
                                                    },
                                                    on: {
                                                      "page-change":
                                                        _vm.readDataFromAPI,
                                                    },
                                                  }),
                                                ]
                                              },
                                              proxy: true,
                                            },
                                          ],
                                          null,
                                          false,
                                          3714690608
                                        ),
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "v-expansion-panel",
                                [
                                  _c("v-expansion-panel-header", [
                                    _vm._v(" Carrier Match List "),
                                  ]),
                                  _c(
                                    "v-expansion-panel-content",
                                    {
                                      staticStyle: {
                                        "max-height": "300px",
                                        "overflow-y": "auto",
                                      },
                                    },
                                    [_c("carrier-match-list")],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }