var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-container",
        { staticClass: "pt-3", attrs: { fluid: "" } },
        [
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { staticClass: "pt-1 pb-0 pr-0", attrs: { cols: "12" } },
                [
                  _c(
                    "v-list-item-subtitle",
                    {
                      staticClass:
                        "blue--text text--lighten-2 white--text box-header d-flex align-center",
                    },
                    [
                      _vm._v(" Load stops "),
                      !_vm.loadEditingDisabled
                        ? _c(
                            "v-btn",
                            {
                              attrs: { color: "grey", small: "", text: "" },
                              on: {
                                click: function ($event) {
                                  return _vm.addStop()
                                },
                              },
                            },
                            [_vm._v(" Add ")]
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-container",
        { class: _vm.$vuetify.breakpoint.mdAndUp ? "pt-0 hgt150" : "pt-0" },
        [
          _c(
            "transition-group",
            { attrs: { name: "list", tag: "div" } },
            [
              _vm._l(_vm.loadStops, function (item, key) {
                return [
                  _c(
                    "v-row",
                    { key: key },
                    [
                      _c(
                        "v-col",
                        {
                          staticClass: "pb-0 pr-1",
                          attrs: { cols: "12", md: "2" },
                        },
                        [
                          _c(
                            "b",
                            {
                              class:
                                item.stopType == 1
                                  ? "green--text"
                                  : "red--text",
                            },
                            [_vm._v("Stop " + _vm._s(key + 1) + ":")]
                          ),
                        ]
                      ),
                      _c(
                        "v-col",
                        {
                          staticClass: "pb-0 px-1",
                          attrs: { cols: "12", md: "8" },
                          on: {
                            click: function ($event) {
                              return _vm.editLoadStop(item.id)
                            },
                          },
                        },
                        [
                          _c("tooltip", {
                            attrs: {
                              "tooltip-content": _vm.loadStopDisplay(item),
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        {
                          staticClass: "pb-0 text-right pr-1",
                          attrs: { cols: "12", md: "2" },
                        },
                        [
                          key > 0
                            ? _c(
                                "v-icon",
                                {
                                  attrs: { small: "" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.moveItemUp(key)
                                    },
                                  },
                                },
                                [_vm._v(" mdi-arrow-up-bold ")]
                              )
                            : _vm._e(),
                          key < _vm.shipmentDetail.loadStops.length - 1
                            ? _c(
                                "v-icon",
                                {
                                  attrs: { small: "" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.moveItemDown(key)
                                    },
                                  },
                                },
                                [_vm._v(" mdi-arrow-down-bold ")]
                              )
                            : _vm._e(),
                          _c(
                            "v-icon",
                            {
                              attrs: { color: "red", small: "" },
                              on: {
                                click: function ($event) {
                                  return _vm.confirmDeleteStop(item, key)
                                },
                              },
                            },
                            [_vm._v(" mdi-delete ")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]
              }),
            ],
            2
          ),
        ],
        1
      ),
      _c("add-load-stop", {
        attrs: {
          "customer-id": _vm.shipmentDetail.customer.id,
          "load-id": _vm.shipmentDetail.id,
        },
      }),
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "290" },
          model: {
            value: _vm.dialog,
            callback: function ($$v) {
              _vm.dialog = $$v
            },
            expression: "dialog",
          },
        },
        [
          _c(
            "v-card",
            [
              _c("v-card-title", { staticClass: "text-h5" }, [
                _vm._v(" Load Stop"),
              ]),
              _c("v-card-text", [
                _c("p", [
                  _vm._v(
                    " Please confirm that you would like to delete stop " +
                      _vm._s(_vm.stopNumber) +
                      ". "
                  ),
                ]),
                _c("p", [_c("b", [_vm._v(_vm._s(_vm.stopName))])]),
                _c("p", [_vm._v(_vm._s(_vm.stopDate))]),
              ]),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "grey darken-1", text: "" },
                      on: {
                        click: function ($event) {
                          _vm.dialog = false
                        },
                      },
                    },
                    [_vm._v(" Cancel ")]
                  ),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "orange darken-1", text: "" },
                      on: {
                        click: function ($event) {
                          return _vm.deleteLoadStop()
                        },
                      },
                    },
                    [_vm._v(" Delete ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }