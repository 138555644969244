var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { staticClass: "pt-0", attrs: { fluid: "" } },
    [
      _c(
        "v-row",
        { staticClass: "mt-0" },
        [
          _c("v-progress-linear", {
            attrs: {
              active: _vm.loadingIcon,
              absolute: "",
              color: "amber",
              indeterminate: "",
            },
          }),
        ],
        1
      ),
      _c(
        "v-row",
        { staticClass: "mt-6 mb-3" },
        [
          _vm.$vuetify.breakpoint.mdAndDown
            ? _c(
                "v-col",
                {
                  staticClass: "pb-0 pl-1 pt-2",
                  attrs: { cols: "12", md: "10", sm: "9" },
                },
                [
                  _c(
                    "v-menu",
                    {
                      attrs: { "close-on-content-click": "", top: "" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "activator",
                            fn: function ({ on, attrs }) {
                              return [
                                _c(
                                  "v-btn",
                                  _vm._g(
                                    _vm._b(
                                      {
                                        staticClass: "mx-2",
                                        attrs: { icon: "", light: "" },
                                      },
                                      "v-btn",
                                      attrs,
                                      false
                                    ),
                                    on
                                  ),
                                  [_c("v-icon", [_vm._v("mdi-dots-vertical")])],
                                  1
                                ),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        1190793684
                      ),
                    },
                    [
                      _c(
                        "v-list",
                        _vm._l(_vm.btnLinks, function (item, index) {
                          return _c(
                            "v-list-item",
                            {
                              key: index,
                              attrs: {
                                disabled: item.disabled,
                                href: item.href,
                              },
                              on: { click: item.action },
                            },
                            [
                              _c(
                                "v-list-item-title",
                                {
                                  class:
                                    item.status &&
                                    !item.disabled &&
                                    "green--text font-weight-medium",
                                },
                                [_vm._v(" " + _vm._s(item.title) + " ")]
                              ),
                            ],
                            1
                          )
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.$vuetify.breakpoint.lgAndUp
            ? _c(
                "v-col",
                {
                  staticClass: "pb-0 pl-1 pt-2",
                  attrs: { cols: "12", md: "10", sm: "9" },
                },
                [
                  _c("load-detail-button", {
                    attrs: {
                      "btn-class": _vm.vpBtn.class,
                      "btn-icon": _vm.vpBtn.img,
                      "button-text": _vm.vpBtn.text,
                      color: _vm.vpBtn.color,
                      href: _vm.vpBtn.href,
                      target: _vm.vpBtn.target,
                    },
                  }),
                  _c("load-detail-button", {
                    attrs: {
                      "btn-class": _vm.rcBtn.class,
                      "btn-icon": _vm.rcBtn.img,
                      "button-text": _vm.rcBtn.text,
                      color: _vm.rcBtn.color,
                      href: _vm.rcBtn.href,
                      target: _vm.rcBtn.target,
                    },
                  }),
                  _c("load-detail-button", {
                    attrs: {
                      "api-call": _vm.srcBtn.apiCall,
                      "btn-class": _vm.srcBtn.class,
                      "btn-icon": _vm.srcBtn.img,
                      "button-text": _vm.srcBtn.text,
                      color: _vm.srcBtn.color,
                      href: _vm.srcBtn.href,
                    },
                  }),
                  _c(
                    "v-btn",
                    {
                      staticClass: "white--text ma-2",
                      attrs: { color: "#1E88E5", small: "" },
                      on: { click: _vm.toggleCarrierMatchListCard },
                    },
                    [
                      _c("v-icon", { staticClass: "pr-2" }, [
                        _vm._v(" mdi-format-list-bulleted"),
                      ]),
                      _vm._v(" Carrier Match List "),
                    ],
                    1
                  ),
                  _c("load-detail-button", {
                    attrs: {
                      "btn-class": _vm.hlBtn.class,
                      "btn-icon": _vm.hlBtn.img,
                      "button-text": _vm.hlBtn.text,
                      color: _vm.hlBtn.color,
                      disabled: _vm.hlBtn.disabled,
                    },
                  }),
                  _c(
                    "v-btn",
                    {
                      staticClass: "white--text ma-2",
                      attrs: {
                        disabled:
                          _vm.shipmentDetail.loadStatus !== 14 ||
                          _vm.shipmentDetail.hold === true,
                        color: "green",
                        small: "",
                      },
                      on: { click: _vm.initiateBilling },
                    },
                    [
                      _c("v-icon", { staticClass: "pr-2" }, [
                        _vm._v(" mdi-cash"),
                      ]),
                      _vm._v(" Initiate Billing "),
                    ],
                    1
                  ),
                  _c(
                    "v-btn",
                    {
                      class: `${
                        _vm.autoPriceEnabled
                          ? "green white-text"
                          : "grey lighten-2 grey--text text--lighten-1 elevation-0"
                      } ma-2`,
                      attrs: { small: "" },
                      on: { click: _vm.toggleAutoPrice },
                    },
                    [
                      _c("v-icon", { staticClass: "pr-2" }, [
                        _vm._v(" mdi-currency-usd"),
                      ]),
                      _vm._v(" Auto Price "),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _c(
            "v-col",
            {
              staticClass: "pb-0 pl-1 pt-0 text-right",
              attrs: { cols: "12", md: "2", sm: "3" },
            },
            [
              _c(
                "v-btn",
                {
                  class: _vm.holdBtn.class,
                  attrs: {
                    color: _vm.shipmentDetail.hold === false ? "red" : "grey",
                    title: _vm.holdBtn.text,
                    small: "",
                  },
                  on: {
                    click: function ($event) {
                      return _vm.holdLoad()
                    },
                  },
                },
                [
                  _c("v-icon", { attrs: { small: "" } }, [
                    _vm._v(" " + _vm._s(_vm.holdBtn.img) + " "),
                  ]),
                ],
                1
              ),
              _c(
                "v-btn",
                {
                  class: _vm.archiveBtn.class,
                  attrs: {
                    color:
                      _vm.shipmentDetail.archived === false ? "red" : "grey",
                    title: _vm.archiveBtn.text,
                    small: "",
                  },
                  on: {
                    click: function ($event) {
                      return _vm.archiveLoad()
                    },
                  },
                },
                [
                  _c("v-icon", { attrs: { small: "" } }, [
                    _vm._v(" " + _vm._s(_vm.archiveBtn.img) + " "),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            {
              staticClass: "pt-0",
              attrs: {
                cols:
                  _vm.carrierMatchListCard && _vm.$vuetify.breakpoint.lgAndUp
                    ? 8
                    : 12,
              },
            },
            [
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "12", lg: "8", md: "8", sm: "12" } },
                    [_c("shipment-detail")],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "12", lg: "4", md: "4", sm: "12" } },
                    [_c("load-tabs")],
                    1
                  ),
                  _vm.carrierMatchListCard && _vm.$vuetify.breakpoint.mdAndDown
                    ? _c(
                        "v-col",
                        {
                          staticClass: "pt-0",
                          attrs: {
                            cols: "12",
                            lg: "4",
                            md: "4",
                            sm: "12",
                            xl: "4",
                          },
                        },
                        [_c("carrier-match-list")],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    {
                      staticClass: "pb-0 pt-0",
                      attrs: { cols: "12", lg: "4", md: "4", sm: "12" },
                    },
                    [
                      _c(
                        "v-card",
                        {
                          staticClass: "mt-0",
                          staticStyle: { overflow: "hidden" },
                          attrs: {
                            height: _vm.$vuetify.breakpoint.mdAndUp
                              ? "385px"
                              : "",
                          },
                        },
                        [_c("tender-detail")],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    {
                      staticClass: "pb-0 pt-0",
                      attrs: { cols: "12", lg: "8", md: "8", sm: "12" },
                    },
                    [
                      _c(
                        "v-card",
                        {
                          staticClass: "pa-0 ma-0",
                          style: _vm.$vuetify.breakpoint.lgAndUp
                            ? "height: 385px"
                            : "height:auto;",
                        },
                        [
                          _c(
                            "v-card-text",
                            { staticClass: "pa-0" },
                            [
                              _c(
                                "v-row",
                                { staticClass: "ma-0" },
                                [
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "pa-0",
                                      attrs: {
                                        cols: "12",
                                        lg: "8",
                                        md: "8",
                                        sm: "12",
                                      },
                                    },
                                    [
                                      _c(
                                        "v-container",
                                        { staticClass: "pa-0" },
                                        [
                                          _c(
                                            "v-row",
                                            { staticClass: "ma-0" },
                                            [
                                              _c(
                                                "v-col",
                                                {
                                                  staticClass: "pa-0",
                                                  staticStyle: {
                                                    "border-bottom":
                                                      "1px solid #ccc",
                                                  },
                                                  attrs: {
                                                    cols: "12",
                                                    md: "6",
                                                    sm: "12",
                                                  },
                                                },
                                                [_c("shipper-info")],
                                                1
                                              ),
                                              _c(
                                                "v-col",
                                                {
                                                  staticClass: "pa-0",
                                                  style: _vm.$vuetify.breakpoint
                                                    .mdAndUp
                                                    ? "border-left:1px solid #CCC; border-bottom:1px solid #CCC;"
                                                    : "border-bottom:1px solid #CCC;",
                                                  attrs: {
                                                    cols: "12",
                                                    md: "6",
                                                    sm: "12",
                                                  },
                                                },
                                                [_c("consignee-info")],
                                                1
                                              ),
                                              _c(
                                                "v-col",
                                                {
                                                  staticClass: "pa-0",
                                                  class: _vm.$vuetify.breakpoint
                                                    .md
                                                    ? "border-none"
                                                    : "",
                                                  style: _vm.$vuetify.breakpoint
                                                    .lgAndUp
                                                    ? ""
                                                    : "border-bottom:1px solid #CCC;",
                                                  attrs: {
                                                    cols: "12",
                                                    md: "6",
                                                    sm: "12",
                                                  },
                                                },
                                                [_c("carrier-info")],
                                                1
                                              ),
                                              _c(
                                                "v-col",
                                                {
                                                  staticClass: "pa-0",
                                                  style: _vm.$vuetify.breakpoint
                                                    .mdAndUp
                                                    ? "border-left:1px solid #CCC;"
                                                    : "border-bottom:1px solid #CCC;",
                                                  attrs: {
                                                    cols: "12",
                                                    md: "6",
                                                    sm: "12",
                                                  },
                                                },
                                                [_c("load-stop")],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _vm.$vuetify.breakpoint.mdAndUp
                                    ? _c(
                                        "v-col",
                                        {
                                          staticClass: "pa-0",
                                          staticStyle: {
                                            "border-left": "1px solid #ccc",
                                            height: "385px",
                                          },
                                          attrs: {
                                            cols: "12",
                                            md: "4",
                                            sm: "12",
                                          },
                                        },
                                        [
                                          _c(
                                            "v-container",
                                            {
                                              staticClass: "pa-0",
                                              attrs: { fluid: "" },
                                            },
                                            [
                                              _c(
                                                "v-row",
                                                { staticClass: "ma-0" },
                                                [
                                                  _c(
                                                    "v-col",
                                                    {
                                                      staticClass: "pa-0",
                                                      staticStyle: {
                                                        "border-bottom":
                                                          "1px solid #ccc",
                                                      },
                                                      attrs: { cols: "12" },
                                                    },
                                                    [
                                                      _c(
                                                        "commodity-dimensions"
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "v-col",
                                                    {
                                                      staticClass: "py-0",
                                                      attrs: { cols: "12" },
                                                    },
                                                    [_c("load-date")],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      )
                                    : _c(
                                        "v-col",
                                        {
                                          staticClass: "pa-0",
                                          attrs: { cols: "12" },
                                        },
                                        [
                                          _c(
                                            "v-container",
                                            {
                                              staticClass: "pa-0",
                                              attrs: { fluid: "" },
                                            },
                                            [
                                              _c(
                                                "v-row",
                                                { staticClass: "ma-0" },
                                                [
                                                  _c(
                                                    "v-col",
                                                    {
                                                      staticClass: "pt-0",
                                                      staticStyle: {
                                                        "border-bottom":
                                                          "1px solid #ccc",
                                                      },
                                                      attrs: { cols: "12" },
                                                    },
                                                    [
                                                      _c(
                                                        "commodity-dimensions"
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "v-col",
                                                    {
                                                      staticClass: "pt-0",
                                                      attrs: { cols: "12" },
                                                    },
                                                    [_c("load-date")],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c("load-match-and-quotes", { attrs: { "load-id": _vm.id } }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }