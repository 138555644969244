var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-dialog",
    {
      attrs: { persistent: "", width: "600" },
      model: {
        value: _vm.addStopDialog,
        callback: function ($$v) {
          _vm.addStopDialog = $$v
        },
        expression: "addStopDialog",
      },
    },
    [
      _c(
        "v-card",
        [
          _c(
            "v-card-title",
            {
              staticClass: "pt-0 pl-0",
              staticStyle: { "background-color": "#1e88e5" },
            },
            [
              _c(
                "v-row",
                { staticClass: "align-center" },
                [
                  _c("v-col", { staticClass: "mt-2", attrs: { cols: "12" } }, [
                    _c("span", { staticClass: "text-h5 white--text" }, [
                      _vm._v(_vm._s(_vm.titleText)),
                    ]),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-card-text",
            { staticClass: "pb-0" },
            [
              _c(
                "v-form",
                {
                  ref: "load-stop-form",
                  model: {
                    value: _vm.valid,
                    callback: function ($$v) {
                      _vm.valid = $$v
                    },
                    expression: "valid",
                  },
                },
                [
                  _c(
                    "v-row",
                    { staticClass: "align-center pt-8 pb-4" },
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "12", md: "6", sm: "6" } },
                        [
                          _c(
                            "v-icon",
                            {
                              staticClass: "float-left mr-2 mt-2",
                              attrs: { color: "#1E88E5" },
                            },
                            [_vm._v(" mdi-map-marker ")]
                          ),
                          _c("simple-location-auto-complete", {
                            attrs: { "customer-id": _vm.customerId },
                            on: { event: _vm.selectedLocation },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "12", md: "6", sm: "6" } },
                        [
                          _c("v-text-field", {
                            staticClass: "mt-n2",
                            attrs: {
                              type: "text",
                              label: "Address",
                              disabled: "",
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "prepend",
                                fn: function () {
                                  return [
                                    _c(
                                      "v-icon",
                                      { attrs: { color: "#1E88E5" } },
                                      [_vm._v(" mdi-assistant ")]
                                    ),
                                  ]
                                },
                                proxy: true,
                              },
                            ]),
                            model: {
                              value: _vm.currentLocation.address,
                              callback: function ($$v) {
                                _vm.$set(_vm.currentLocation, "address", $$v)
                              },
                              expression: "currentLocation.address",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "12", md: "6", sm: "6" } },
                        [
                          _c("v-text-field", {
                            staticClass: "mt-n2",
                            attrs: {
                              type: "text",
                              label: "City",
                              disabled: "",
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "prepend",
                                fn: function () {
                                  return [
                                    _c(
                                      "v-icon",
                                      { attrs: { color: "#1E88E5" } },
                                      [_vm._v(" mdi-city ")]
                                    ),
                                  ]
                                },
                                proxy: true,
                              },
                            ]),
                            model: {
                              value: _vm.currentLocation.city,
                              callback: function ($$v) {
                                _vm.$set(_vm.currentLocation, "city", $$v)
                              },
                              expression: "currentLocation.city",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "12", md: "6", sm: "6" } },
                        [
                          _c("v-text-field", {
                            staticClass: "mt-n2",
                            attrs: {
                              type: "text",
                              label: "State",
                              disabled: "",
                            },
                            model: {
                              value: _vm.currentLocation.stateProvinceRegion,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.currentLocation,
                                  "stateProvinceRegion",
                                  $$v
                                )
                              },
                              expression: "currentLocation.stateProvinceRegion",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "12", md: "6", sm: "6" } },
                        [
                          _c("v-text-field", {
                            staticClass: "mt-n2",
                            attrs: {
                              type: "text",
                              label: "Postal Code",
                              disabled: "",
                            },
                            model: {
                              value: _vm.currentLocation.postalCode,
                              callback: function ($$v) {
                                _vm.$set(_vm.currentLocation, "postalCode", $$v)
                              },
                              expression: "currentLocation.postalCode",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "12", md: "6", sm: "6" } },
                        [
                          _c(
                            "v-menu",
                            {
                              attrs: {
                                "close-on-content-click": false,
                                transition: "scale-transition",
                                "offset-y": "",
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "activator",
                                  fn: function ({ on, attrs }) {
                                    return [
                                      _c(
                                        "v-text-field",
                                        _vm._g(
                                          _vm._b(
                                            {
                                              staticClass: "pt-0",
                                              attrs: {
                                                label: "Stop Date",
                                                rules: [_vm.rules.required],
                                                "persistent-hint": "",
                                                readonly: "",
                                              },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "prepend",
                                                    fn: function () {
                                                      return [
                                                        _c(
                                                          "v-icon",
                                                          {
                                                            attrs: {
                                                              color: "#1E88E5",
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              " mdi-calendar "
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    },
                                                    proxy: true,
                                                  },
                                                ],
                                                null,
                                                true
                                              ),
                                              model: {
                                                value: _vm.stopDate,
                                                callback: function ($$v) {
                                                  _vm.stopDate = $$v
                                                },
                                                expression: "stopDate",
                                              },
                                            },
                                            "v-text-field",
                                            attrs,
                                            false
                                          ),
                                          on
                                        )
                                      ),
                                    ]
                                  },
                                },
                              ]),
                              model: {
                                value: _vm.stopDateCalendar,
                                callback: function ($$v) {
                                  _vm.stopDateCalendar = $$v
                                },
                                expression: "stopDateCalendar",
                              },
                            },
                            [
                              _c("v-date-picker", {
                                attrs: { "no-title": "" },
                                on: {
                                  input: function ($event) {
                                    _vm.stopDateCalendar = false
                                  },
                                },
                                model: {
                                  value: _vm.stopDate,
                                  callback: function ($$v) {
                                    _vm.stopDate = $$v
                                  },
                                  expression: "stopDate",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "12", md: "6", sm: "6" } },
                        [
                          _c("v-text-field", {
                            directives: [
                              {
                                name: "mask",
                                rawName: "v-mask",
                                value: "##:##",
                                expression: "'##:##'",
                              },
                            ],
                            staticClass: "pt-0",
                            attrs: {
                              label: "Early Time",
                              rules: [_vm.rules.time],
                            },
                            model: {
                              value: _vm.earlyTime,
                              callback: function ($$v) {
                                _vm.earlyTime = $$v
                              },
                              expression: "earlyTime",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "12", md: "6", sm: "6" } },
                        [
                          _c("v-text-field", {
                            directives: [
                              {
                                name: "mask",
                                rawName: "v-mask",
                                value: "##:##",
                                expression: "'##:##'",
                              },
                            ],
                            staticClass: "pt-0",
                            attrs: {
                              label: "Late Time",
                              rules: [_vm.rules.time],
                            },
                            model: {
                              value: _vm.lateTime,
                              callback: function ($$v) {
                                _vm.lateTime = $$v
                              },
                              expression: "lateTime",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "12", md: "6", sm: "6" } },
                        [
                          _c("v-text-field", {
                            attrs: {
                              type: "text",
                              label: "Phone",
                              rules: [_vm.rules.phone],
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "prepend",
                                fn: function () {
                                  return [
                                    _c(
                                      "v-icon",
                                      { attrs: { color: "#1E88E5" } },
                                      [_vm._v(" mdi-phone ")]
                                    ),
                                  ]
                                },
                                proxy: true,
                              },
                            ]),
                            model: {
                              value: _vm.phone,
                              callback: function ($$v) {
                                _vm.phone = $$v
                              },
                              expression: "phone",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "12", md: "6", sm: "6" } },
                        [
                          _c("v-text-field", {
                            attrs: {
                              type: "text",
                              label: "Contact Name",
                              rules: [_vm.rules.alphaNumeric],
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "prepend",
                                fn: function () {
                                  return [
                                    _c(
                                      "v-icon",
                                      { attrs: { color: "#1E88E5" } },
                                      [_vm._v(" mdi-account ")]
                                    ),
                                  ]
                                },
                                proxy: true,
                              },
                            ]),
                            model: {
                              value: _vm.contactName,
                              callback: function ($$v) {
                                _vm.contactName = $$v
                              },
                              expression: "contactName",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "12", md: "6", sm: "6" } },
                        [
                          _c("v-text-field", {
                            attrs: { type: "text", label: "Notes" },
                            scopedSlots: _vm._u([
                              {
                                key: "prepend",
                                fn: function () {
                                  return [
                                    _c(
                                      "v-icon",
                                      { attrs: { color: "#1E88E5" } },
                                      [_vm._v(" mdi-note ")]
                                    ),
                                  ]
                                },
                                proxy: true,
                              },
                            ]),
                            model: {
                              value: _vm.notes,
                              callback: function ($$v) {
                                _vm.notes = $$v
                              },
                              expression: "notes",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "12", md: "6", sm: "6" } },
                        [
                          _c("v-select", {
                            staticClass: "mt-1",
                            attrs: {
                              items: _vm.stopTypes,
                              label: "Type",
                              "item-text": "text",
                              "item-value": "id",
                              rules: [_vm.rules.required],
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "prepend",
                                fn: function () {
                                  return [
                                    _c(
                                      "v-icon",
                                      { attrs: { color: "#1E88E5" } },
                                      [_vm._v(" mdi-stop ")]
                                    ),
                                  ]
                                },
                                proxy: true,
                              },
                            ]),
                            model: {
                              value: _vm.stopType,
                              callback: function ($$v) {
                                _vm.stopType = $$v
                              },
                              expression: "stopType",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-card-actions",
            { staticClass: "pt-0" },
            [
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  attrs: { color: "grey darken-1", text: "" },
                  on: {
                    click: function ($event) {
                      return _vm.closeDialog()
                    },
                  },
                },
                [_vm._v(" Close ")]
              ),
              _c(
                "v-btn",
                {
                  attrs: { color: "orange darken-1", text: "" },
                  on: {
                    click: function ($event) {
                      return _vm.submitAddStop()
                    },
                  },
                },
                [_vm._v(" Submit ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }