var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { staticClass: "pa-0" },
    [
      _vm.mapType == _vm.mapTypes.googleMap
        ? [
            _c("GmapMap", {
              ref: "mapRef",
              style: _vm.$vuetify.breakpoint.mdAndUp
                ? "height: 350px; border-radius: 0px 4px 4px 0px"
                : "height: 350px; border-radius: 0px 0px 4px 0px",
              attrs: {
                id: "map",
                center: { lat: 37.77, lng: -122.447 },
                zoom: 9,
                "map-type-id": "terrain",
              },
            }),
          ]
        : _vm.mapType == _vm.mapTypes.hereMap
        ? [
            _c("here-map", {
              attrs: {
                center: { lat: 37.77, lng: -122.447 },
                destination: _vm.destination,
                origin: _vm.origin,
                "way-points": _vm.loadStops,
                height: "350px",
                width: "100%",
              },
            }),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }