var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-tooltip",
    {
      attrs: { bottom: "" },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function ({ on, attrs }) {
            return [
              _c(
                "v-btn",
                _vm._b(
                  {
                    class: _vm.btnClass,
                    attrs: {
                      color: _vm.color,
                      disabled: _vm.disabled,
                      href: _vm.buttonRedirect,
                      target: _vm.target,
                      small: "",
                    },
                    on: {
                      click: function ($event) {
                        return _vm.sendAuthenticatedApiRequest()
                      },
                    },
                  },
                  "v-btn",
                  attrs,
                  false
                ),
                [
                  _c("v-icon", { attrs: { left: "", small: "" } }, [
                    _vm._v(" " + _vm._s(_vm.btnIcon) + " "),
                  ]),
                  _vm._v(" " + _vm._s(_vm.buttonText) + " "),
                ],
                1
              ),
            ]
          },
        },
      ]),
    },
    [_c("span", [_vm._v(_vm._s(_vm.buttonText))])]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }