var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { staticClass: "pa-0" },
    [
      _c(
        "v-card",
        { staticClass: "mx-auto mt-0 mb-0", attrs: { left: "", light: "" } },
        [
          _c(
            "v-card-text",
            { staticClass: "pa-0" },
            [
              _c(
                "v-row",
                [
                  !_vm.shipmentDetailEdit
                    ? _c(
                        "v-col",
                        {
                          staticClass: "hgt350 pt-0 px-0",
                          attrs: { cols: "12", lg: "6", md: "6", sm: "12" },
                        },
                        [
                          _c("load-tracking"),
                          _c(
                            "v-container",
                            { staticClass: "pb-1 pt-0 px-0" },
                            [
                              _c(
                                "v-row",
                                [
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "px-0 pt-0",
                                      attrs: { cols: "3" },
                                    },
                                    [
                                      _vm.shipmentDetail.loadStops
                                        ? _c(
                                            "div",
                                            { staticStyle: { width: "100%" } },
                                            [
                                              _c(
                                                "v-chip",
                                                {
                                                  attrs: {
                                                    color: "orange",
                                                    label: "",
                                                    "text-color": "white",
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "v-icon",
                                                    { attrs: { left: "" } },
                                                    [_vm._v(" mdi-stop")]
                                                  ),
                                                  _c("b", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.shipmentDetail
                                                          .loadStops.length
                                                      ) + " Stop(s)"
                                                    ),
                                                  ]),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      _c(
                                        "div",
                                        {
                                          staticClass: "pl-2",
                                          class: _vm.shipmentDetail.loadStops
                                            ? "pt-1"
                                            : "pt-3",
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(_vm.shipmentDetail.id) +
                                              " "
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "px-0",
                                      attrs: { cols: "1" },
                                    },
                                    [
                                      _c("v-img", {
                                        directives: [
                                          {
                                            name: "show",
                                            rawName: "v-show",
                                            value: _vm.shipmentDetail.loadboard,
                                            expression:
                                              "shipmentDetail.loadboard",
                                          },
                                        ],
                                        attrs: {
                                          contain: "",
                                          height: "20",
                                          src: require("@/assets/DAT-logo-blue.svg"),
                                          width: "60",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "pl-0 pr-0",
                                      attrs: { cols: "1" },
                                    },
                                    [
                                      _c("v-img", {
                                        directives: [
                                          {
                                            name: "show",
                                            rawName: "v-show",
                                            value: _vm.shipmentDetail.loadboard,
                                            expression:
                                              "shipmentDetail.loadboard",
                                          },
                                        ],
                                        attrs: {
                                          contain: "",
                                          height: "20",
                                          src: require("@/assets/truckstop.png"),
                                          width: "20",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "pl-0 pr-0",
                                      attrs: { cols: "2" },
                                    },
                                    [
                                      _vm.loadId
                                        ? _c("loadboard-manager", {
                                            attrs: {
                                              "load-id": _vm.loadId,
                                              "loadboard-post-statuses":
                                                _vm.loadboardPostStatuses,
                                              "load-status":
                                                _vm.shipmentDetail
                                                  .loadStatusDisplay,
                                            },
                                            on: {
                                              "update-statuses":
                                                _vm.handleUpdateStatuses,
                                            },
                                          })
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "text-right pr-0 pl-0",
                                      attrs: { cols: "5" },
                                    },
                                    [
                                      _c("load-status-button", {
                                        attrs: {
                                          "agent-detail": _vm.agentDetail,
                                          "load-status-items":
                                            _vm.loadStatusChoices,
                                          "shipment-detail": _vm.shipmentDetail,
                                          "disable-click":
                                            _vm.isLoadStatusChangeDisabled,
                                        },
                                      }),
                                      !_vm.disableStatusButton
                                        ? _c(
                                            "v-btn",
                                            {
                                              attrs: {
                                                color: "grey",
                                                small: "",
                                                text: "",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.shipmentDetailEditing()
                                                },
                                              },
                                            },
                                            [_vm._v(" Edit ")]
                                          )
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-container",
                            { staticClass: "pb-1 pt-0" },
                            [
                              _c(
                                "v-list-item-subtitle",
                                { staticClass: "grey--text text--darken-1" },
                                [_vm._v(" Customer ")]
                              ),
                              _vm.shipmentDetail.customer
                                ? _c(
                                    "v-list-item-title",
                                    {
                                      staticClass:
                                        "text-h5 pl-0 grey--text text--darken-4",
                                    },
                                    [
                                      _c(
                                        "router-link",
                                        {
                                          attrs: {
                                            to: {
                                              name: "Customer Detail",
                                              params: {
                                                id: _vm.shipmentDetail.customer
                                                  .id,
                                              },
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.shipmentDetail.customer.name
                                              ) +
                                              " "
                                          ),
                                          _vm.invoicePaidText
                                            ? _c(
                                                "v-tooltip",
                                                {
                                                  attrs: { right: "" },
                                                  scopedSlots: _vm._u(
                                                    [
                                                      {
                                                        key: "activator",
                                                        fn: function ({
                                                          on,
                                                          attrs,
                                                        }) {
                                                          return [
                                                            _c(
                                                              "span",
                                                              _vm._g(
                                                                _vm._b(
                                                                  {
                                                                    staticClass:
                                                                      "ml-0 text-truncate truncate",
                                                                  },
                                                                  "span",
                                                                  attrs,
                                                                  false
                                                                ),
                                                                on
                                                              ),
                                                              [
                                                                _c(
                                                                  "v-icon",
                                                                  {
                                                                    attrs: {
                                                                      medium:
                                                                        "",
                                                                    },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      " mdi-cash-check "
                                                                    ),
                                                                  ]
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                          ]
                                                        },
                                                      },
                                                    ],
                                                    null,
                                                    false,
                                                    3207721643
                                                  ),
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    { staticClass: "box" },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            _vm.invoicePaidText
                                                          ) +
                                                          " "
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                : _c(
                                    "v-list-item-title",
                                    {
                                      staticClass:
                                        "text-h5 pl-0 grey--text text--darken-4",
                                    },
                                    [_vm._v(" Customer not assigned ")]
                                  ),
                            ],
                            1
                          ),
                          _vm.$vuetify.breakpoint.lgAndUp &&
                          !_vm.carrierMatchListCard
                            ? _c("div", [_c("progress-stepper")], 1)
                            : _c(
                                "div",
                                [
                                  _c(
                                    "v-container",
                                    [
                                      _c(
                                        "v-row",
                                        {
                                          attrs: {
                                            justify: "center",
                                            "no-gutters": "",
                                          },
                                        },
                                        [
                                          _c(
                                            "v-col",
                                            { attrs: { cols: "4" } },
                                            [
                                              _c(
                                                "p",
                                                {
                                                  staticClass:
                                                    "mb-1 grey--text text--darken-1 text-center",
                                                },
                                                [_vm._v(" Pickup Date ")]
                                              ),
                                              _c(
                                                "v-card-text",
                                                {
                                                  staticClass:
                                                    "text--regular pa-0 grey--text text--darken-4 text-center",
                                                },
                                                [
                                                  _c("render-date", {
                                                    attrs: {
                                                      date: _vm.shipmentDetail
                                                        .pickupDate,
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-col",
                                            {
                                              staticClass: "text-center",
                                              attrs: { cols: "2" },
                                            },
                                            [
                                              _c(
                                                "v-icon",
                                                { staticClass: "pt-3" },
                                                [
                                                  _vm._v(
                                                    " mdi-arrow-right-bold"
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-col",
                                            { attrs: { cols: "4" } },
                                            [
                                              _c(
                                                "p",
                                                {
                                                  staticClass:
                                                    "mb-1 grey--text text--darken-1 text-center",
                                                },
                                                [_vm._v(" Delivery Date ")]
                                              ),
                                              _c(
                                                "v-card-text",
                                                {
                                                  staticClass:
                                                    "text--regular pa-0 grey--text text--darken-4 text-center",
                                                },
                                                [
                                                  _c("render-date", {
                                                    attrs: {
                                                      date: _vm.shipmentDetail
                                                        .deliveryDate,
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                          _c(
                            "v-row",
                            {
                              staticClass: "pl-3 pt-1",
                              attrs: { "no-gutters": "" },
                            },
                            [
                              _c(
                                "v-col",
                                {
                                  staticClass: "xs pb-2",
                                  attrs: { cols: "6", md: "4", sm: "4" },
                                },
                                [
                                  _c(
                                    "p",
                                    {
                                      staticClass:
                                        "mb-1 grey--text text--darken-1",
                                    },
                                    [_vm._v("Equipment")]
                                  ),
                                  _c(
                                    "v-card-text",
                                    {
                                      staticClass:
                                        "text--regular pa-0 grey--text text--darken-4",
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.shipmentDetail.equipmentType
                                          ) +
                                          " "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                {
                                  staticClass: "xs pb-2",
                                  attrs: { cols: "6", md: "4", sm: "4" },
                                },
                                [
                                  _c(
                                    "p",
                                    {
                                      staticClass:
                                        "mb-1 grey--text text--darken-1",
                                    },
                                    [_vm._v("Order Type")]
                                  ),
                                  _vm.shipmentDetail.orderTypeDisplay
                                    ? _c(
                                        "v-card-text",
                                        {
                                          staticClass:
                                            "text--regular pa-0 grey--text text--darken-4",
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.shipmentDetail
                                                  .orderTypeDisplay
                                              ) +
                                              " "
                                          ),
                                        ]
                                      )
                                    : _c(
                                        "v-card-text",
                                        {
                                          staticClass:
                                            "text--regular pa-0 grey--text text--darken-4",
                                        },
                                        [_vm._v(" - ")]
                                      ),
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                {
                                  staticClass: "xs pt-0 pb-2",
                                  attrs: { cols: "6", md: "4", sm: "4" },
                                },
                                [
                                  _c(
                                    "p",
                                    {
                                      staticClass:
                                        "mb-1 grey--text text--darken-1",
                                    },
                                    [_vm._v("Mode")]
                                  ),
                                  _vm.shipmentDetail.mode
                                    ? _c(
                                        "v-card-text",
                                        {
                                          staticClass:
                                            "text--regular pa-0 grey--text text--darken-4",
                                        },
                                        [
                                          _c("tooltip", {
                                            attrs: {
                                              "tooltip-content":
                                                _vm.shipmentDetail.modeDisplay,
                                            },
                                          }),
                                        ],
                                        1
                                      )
                                    : _c(
                                        "v-card-text",
                                        {
                                          staticClass:
                                            "text--regular pa-0 grey--text text--darken-4",
                                        },
                                        [_vm._v(" - ")]
                                      ),
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                {
                                  staticClass: "xs pb-2",
                                  attrs: { cols: "6", md: "4", sm: "4" },
                                },
                                [
                                  _c(
                                    "p",
                                    {
                                      staticClass:
                                        "mb-1 grey--text text--darken-1",
                                    },
                                    [_vm._v("Sales Rep")]
                                  ),
                                  _vm.shipmentDetail.agent
                                    ? _c(
                                        "v-card-text",
                                        {
                                          staticClass:
                                            "text--regular pa-0 grey--text text--darken-4",
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.shipmentDetail.agent.name
                                              ) +
                                              " "
                                          ),
                                        ]
                                      )
                                    : _c(
                                        "v-card-text",
                                        {
                                          staticClass:
                                            "text--regular pa-0 grey--text text--darken-4",
                                        },
                                        [_vm._v(" - ")]
                                      ),
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                {
                                  staticClass: "xs pb-2",
                                  attrs: { cols: "6", md: "4", sm: "4" },
                                },
                                [
                                  _c(
                                    "p",
                                    {
                                      staticClass:
                                        "mb-1 grey--text text--darken-1",
                                    },
                                    [_vm._v("Account Manager")]
                                  ),
                                  _vm.shipmentDetail.customer
                                    ? _c(
                                        "v-card-text",
                                        {
                                          staticClass:
                                            "text--regular pa-0 grey--text text--darken-4",
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.shipmentDetail.customer
                                                  .account_manager_display
                                              ) +
                                              " "
                                          ),
                                        ]
                                      )
                                    : _c(
                                        "v-card-text",
                                        {
                                          staticClass:
                                            "text--regular pa-0 grey--text text--darken-4",
                                        },
                                        [_vm._v(" - ")]
                                      ),
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                {
                                  staticClass: "xs pb-2",
                                  attrs: { cols: "6", md: "4", sm: "4" },
                                },
                                [
                                  _c(
                                    "p",
                                    {
                                      staticClass:
                                        "mb-1 grey--text text--darken-1",
                                    },
                                    [_vm._v("CSR")]
                                  ),
                                  _vm.shipmentDetail.carrierSalesRep
                                    ? _c(
                                        "v-card-text",
                                        {
                                          staticClass:
                                            "text--regular pa-0 grey--text text--darken-4",
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.shipmentDetail
                                                  .carrierSalesRep.name
                                              ) +
                                              " "
                                          ),
                                        ]
                                      )
                                    : _c(
                                        "v-card-text",
                                        {
                                          staticClass:
                                            "text--regular pa-0 grey--text text--darken-4",
                                        },
                                        [_vm._v(" - ")]
                                      ),
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                {
                                  staticClass: "xs pb-2",
                                  attrs: { cols: "6", md: "4", sm: "4" },
                                },
                                [
                                  _c(
                                    "p",
                                    {
                                      staticClass:
                                        "mb-1 grey--text text--darken-1",
                                    },
                                    [_vm._v("Customer Ref. ID")]
                                  ),
                                  _vm.shipmentDetail.customerReferenceId
                                    ? _c(
                                        "v-card-text",
                                        {
                                          staticClass:
                                            "text--regular pa-0 grey--text text--darken-4",
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.shipmentDetail
                                                  .customerReferenceId
                                              ) +
                                              " "
                                          ),
                                        ]
                                      )
                                    : _c(
                                        "v-card-text",
                                        {
                                          staticClass:
                                            "text--regular pa-0 grey--text text--darken-4",
                                        },
                                        [_vm._v(" - ")]
                                      ),
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                {
                                  staticClass: "xs pb-2",
                                  attrs: { cols: "6", md: "4", sm: "4" },
                                },
                                [
                                  _c(
                                    "p",
                                    {
                                      staticClass:
                                        "mb-1 grey--text text--darken-1",
                                    },
                                    [_vm._v("PO Number")]
                                  ),
                                  _vm.shipmentDetail.poNumber
                                    ? _c(
                                        "v-card-text",
                                        {
                                          staticClass:
                                            "text--regular pa-0 grey--text text--darken-4",
                                          staticStyle: {
                                            "text-overflow": "ellipsis",
                                            "white-space": "nowrap",
                                            overflow: "hidden",
                                          },
                                          attrs: {
                                            title: _vm.shipmentDetail.poNumber,
                                          },
                                        },
                                        [
                                          _c("tooltip", {
                                            attrs: {
                                              "tooltip-content":
                                                _vm.shipmentDetail.poNumber,
                                            },
                                          }),
                                        ],
                                        1
                                      )
                                    : _c(
                                        "v-card-text",
                                        {
                                          staticClass:
                                            "text--regular pa-0 grey--text text--darken-4",
                                        },
                                        [_vm._v(" - ")]
                                      ),
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                {
                                  staticClass: "xs pb-2",
                                  attrs: { cols: "6", md: "4", sm: "4" },
                                },
                                [
                                  _c(
                                    "p",
                                    {
                                      staticClass:
                                        "mb-1 grey--text text--darken-1",
                                    },
                                    [_vm._v("Carrier Invoice #")]
                                  ),
                                  _vm.shipmentDetail.carrierInvoiceNumber
                                    ? _c(
                                        "v-card-text",
                                        {
                                          staticClass:
                                            "text--regular pa-0 grey--text text--darken-4",
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.shipmentDetail
                                                  .carrierInvoiceNumber
                                              ) +
                                              " "
                                          ),
                                        ]
                                      )
                                    : _c(
                                        "v-card-text",
                                        {
                                          staticClass:
                                            "text--regular pa-0 grey--text text--darken-4",
                                        },
                                        [_vm._v(" - ")]
                                      ),
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                {
                                  staticClass: "xs pb-2",
                                  attrs: { cols: "6", md: "4", sm: "4" },
                                },
                                [
                                  _c(
                                    "p",
                                    {
                                      staticClass:
                                        "mb-1 grey--text text--darken-1",
                                    },
                                    [_vm._v("Billing Ref. Number")]
                                  ),
                                  _c(
                                    "v-card-text",
                                    {
                                      staticClass:
                                        "text--regular pa-0 grey--text text--darken-4",
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.shipmentDetail
                                              .billingReferenceNumber || "-"
                                          ) +
                                          " "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _c(
                        "v-col",
                        {
                          staticClass: "xs hgt350 pt-0 px-0",
                          attrs: {
                            cols: "12",
                            lg: "6",
                            md: "6",
                            sm: "12",
                            xl: "6",
                          },
                        },
                        [_c("shipment-detail-edit")],
                        1
                      ),
                  _c(
                    "v-col",
                    {
                      staticClass: "pa-0 pl-3",
                      attrs: {
                        cols: "12",
                        lg: "6",
                        md: "6",
                        sm: "12",
                        xl: "6",
                      },
                    },
                    [_c("shipment-map")],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }